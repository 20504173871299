(function ($) {

    var $body = $('body');

    var locale = $body.data('locale');
    var pt_code = 620;

    $(function () {

        // Data tables
        // $('[data-toggle="tooltip"]').tooltip();

        $('.footable').footable();


        // Summernote

        $('.summernote').summernote({
            height: 350,
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['para', ['ul', 'ol']],
                ['misc', ['fullscreen', 'codeview']],
            ],
        });

      $("span.pie").peity("pie", {
        fill: ['#1ab394', '#d7d7d7', '#ffffff']
      })

        // Select 2

        // $.fn.select2.defaults.set( "theme", "bootstrap" );
                // $('.select2').select2({
        //     width: '100%',
        //     allowClear: true,
        //     placeholder: ''
        //     // theme: 'bootstrap',
        // });

        $(".colorpicker").colorpicker();
        
        $('.clockpicker').clockpicker();
        
        // DatePicker
        $("#start_date").datepicker({
            weekStart: 1,
            keyboardNavigation: false,
            todayHighlight: true,
            forceParse: false,
            calendarWeeks: true,
            todayBtn: 1,
            autoclose: true,
            format: "dd-mm-yyyy",
            language: locale
        }).on('changeDate', function (selected) {
            var minDate = new Date(selected.date.valueOf());
            $('#end_date').datepicker('setStartDate', minDate);
        });

        $("#date").datepicker({
            weekStart: 1,
            keyboardNavigation: false,
            todayHighlight: true,
            forceParse: false,
            calendarWeeks: true,
            todayBtn: 1,
            autoclose: true,
            format: "dd-mm-yyyy",
            language: locale
        });

        $("#deadline").datepicker({
          weekStart: 1,
          keyboardNavigation: false,
          todayHighlight: true,
          forceParse: false,
          calendarWeeks: true,
          todayBtn: 1,
          autoclose: true,
          format: "dd-mm-yyyy",
          language: locale
        });
      
      $("#birthday").datepicker({
        weekStart: 1,
        keyboardNavigation: false,
        todayHighlight: true,
        forceParse: false,
        calendarWeeks: true,
        todayBtn: 1,
        autoclose: true,
        format: "dd-mm-yyyy",
        language: locale
      });

        $("#end_date").datepicker({
            weekStart: 1,
            keyboardNavigation: false,
            todayHighlight: true,
            forceParse: false,
            calendarWeeks: true,
            todayBtn: 1,
            autoclose: true,
            format: "dd-mm-yyyy",
            language: locale
        }).on('changeDate', function (selected) {
            var minDate = new Date(selected.date.valueOf());
            $('#start_date').datepicker('setEndDate', minDate);
        });

        // Tooltip



        // Button group checked initializaton

        if ($('.btn-group').length) {
            $('[checked="checked"]').parent().click();
        };


        // Switcher

        var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));

        elems.forEach(function (html) {
            var switchery = new Switchery(html, {
                color: '#1AB394',
                size: 'small'
            });
        });


        // i-Checks

        $('.i-checks').iCheck({
            checkboxClass: 'icheckbox_square-green',
            radioClass: 'iradio_square-green',
        });


        // Modals

        $('*[data-show]').modal('show');

        // $('input:radio[data-toggle="modal"]').click(function(){
        //     $(this).modal('show');
        // });

    });


    // ------------------------------------------------------------------------


    var documentErrorHandler = function () {
        var error = $('#document-error');
        var modal = $('#document-modal');

        if (error !== null && error.val() === 'true') {
            console.log(error.val());
            modal.modal('show');
        }
    }();

    /*
    |--------------------------------------------------------------------------
    | EVENTS
    |--------------------------------------------------------------------------
    */

    $('a[data-confirm]').on('click', function (e) {

        e.preventDefault();

        var message = $(this).data('confirm');
        var url = $(this).attr('href');

        // Show dialogue
        bootbox.confirm({
            size: 'small',
            message: message,
            callback: function (confirmed) {
                if (confirmed) {
                    window.location.href = url;
                }
            }
        });
    });

    $('form[data-confirm]').on('submit', function (e) {

        e.preventDefault();

        var message = $(this).data('confirm');
        var currentForm = this;

        // Show dialogue
        bootbox.confirm({
            size: 'small',
            message: message,
            callback: function (confirmed) {
                if (confirmed) {
                    currentForm.submit();
                }
            }
        });
    });


    $('.btn-file :file').on('change', function () {
        var input = $(this),
            numFiles = input.get(0).files ? input.get(0).files.length : 1,
            label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
        input.trigger('fileselect', [numFiles, label]);
    });


    $('.btn-file :file').on('fileselect', function (event, numFiles, label) {

        var input = $(this).parents('.input-group').find(':text'),
            log = numFiles > 1 ? numFiles + ' files selected' : label;

        if (input.length) {
            input.val(log);
        } else {
            if (log) alert(log);
        }
    });


    /**
     * Prevent enter from submitting form
     */
    $('.prevent-enter').on('keypress keydown keyup', function (e) {
        if (e.which == 13 && !$(e.target).is('textarea')) {
            e.preventDefault();
            return false;
        }
    });


    /*
    |--------------------------------------------------------------------------
    | FUNCTIONS
    |--------------------------------------------------------------------------
    */

    function getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }


    /*
    |--------------------------------------------------------------------------
    | DEFAULTS
    |--------------------------------------------------------------------------
    */

    bootbox.setDefaults({
        /**
         * @optional String
         * @default: en
         * which locale settings to use to translate the three
         * standard button labels: OK, CONFIRM, CANCEL
         */
        locale: locale,

        /**
         * @optional Boolean
         * @default: true
         * whether the dialog should be shown immediately
         */
        show: true,

        /**
         * @optional Boolean
         * @default: true
         * whether the dialog should be have a backdrop or not
         */
        backdrop: true,

        /**
         * @optional Boolean
         * @default: true
         * show a close button
         */
        closeButton: false,

        /**
         * @optional Boolean
         * @default: true
         * animate the dialog in and out (not supported in < IE 10)
         */
        animate: true,

    });

})(jQuery);
